import Vue from 'vue';
import Vuex from 'vuex';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { IPartitionSet } from '../../types/IPartition';
import AppConfig from '../../AppConfig';
import { List as ImmList } from 'immutable';
import { ICustomField } from '../../types/ICustomField';
import { IUserOrganization } from '../../types/IUser';

Vue.use(Vuex);

const state = {
    activePage: '',
    activePartitionIds: [],
    appError: null,
    chartDateRange: [new Date(2000, 1, 1).valueOf(), new Date().valueOf()],
    customFields: ImmList<ICustomField>(),
    displayView: AppConfig.Defaults.displayView,
    fileToImport: null,
    notificationPaneIsOpen: false,
    organizationUsers: [],
    organizationIssuers: [],
    organizationTokens: ImmList<IUserOrganization>(),
    partitionSets: ImmList<IPartitionSet>(),
    searchResults: [],
    searchValue: '',
    user: null,
    organization: null,
};

const VueStore = new Vuex.Store({
    actions,
    getters,
    mutations,
    state,
    strict: process.env.NODE_ENV !== 'production',
});
export default VueStore;
export * from './mutation-types';
