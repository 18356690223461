import Rx from 'rx';
import { get, set, clear as idbClear, del as idbDel, entries as idbEntries, createStore } from 'idb-keyval';
import AppConfig from './AppConfig';

enum NSValues {
    activityPerfStats = 'stats.activityPerf',
    campaignPerfStats = 'stats.campaignPerf',
    configurationPerfStats = 'stats.configurationPerf',
    editor = 'editor',
    importCustomFields = 'import.cf',
    lam = 'lam',
    listStats = 'stats.list',
    audienceStats = 'stats.audience',
    notification = 'notifications',
    search = 'search',
    stats = 'stats',
    template = 'template',
    templateVersions = 'templateVersions',
    templateList = 'templateList',
    workflowOpened = 'workflow.opened',
}

const constructKey = (prefix: NSValues, key: string, orgName: string) => {
    return `${orgName}.${prefix}.${key}`;
};

class Cache {
    public static getInstance(): Cache {
        return new Cache();
    }

    public readonly ns = NSValues;
    public readonly stores = { app: 'app' };
    private static _instance: Cache;
    private taguchiIdb: any = null;
    public constructor() {
        if (!Cache._instance) {
            Cache._instance = this;
            this.taguchiIdb = createStore('taguchi', 'kvstore');
            this.cleanup();
        }
        return Cache._instance;
    }

    /**
     * Store a value to the cache storage with the given prefix, key, and store params
     *
     * @param {NSValues} prefix the key prefix to store the value in
     * @param {string} key the key to store the value in
     * @param {any} value the value to store
     * @param {string} orgName the partition to store the value in
     * @return {Rx.Observable} an Observable of any type
     */
    public setItem(prefix: NSValues, key: any, value: any, orgName: string) {
        const storeKey = constructKey(prefix, key, orgName);
        return Rx.Observable.fromPromise(
            set(storeKey, value, this.taguchiIdb).then(() => get(storeKey, this.taguchiIdb))
        );
    }

    /**
     * Store a value to the cache storage with the given prefix, key, and store params
     *
     * @param {NSValues} prefix the key prefix to store the value in
     * @param {string} key the key to store the value in
     * @param {any} value the value to store
     * @param {string} orgName the partition to store the value in
     * @return {Promise<unknown>} a promise that resolves when the value is stored

     */
    public set(prefix: NSValues, key: any, value: any, orgName: string) {
        return set(constructKey(prefix, key, orgName), value, this.taguchiIdb);
    }

    /**
     * Return a value from the cache storage with the given prefix, key, and store params
     *
     * @param {NSValues} prefix the key prefix to store the value in
     * @param {string} key the key to store the value in
     * @param {any} value the value to store
     * @param {string} orgName the partition to store the value in
     * @return an Observable of any type
     */
    public getItem(prefix: NSValues, key: any, orgName: string): Rx.Observable<any> {
        const storeKey = constructKey(prefix, key, orgName);
        const getPromise = get(storeKey, this.taguchiIdb);
        return Rx.Observable.fromPromise(getPromise);
    }

    /**
     * Return a value from the cache storage with the given prefix, key, and store params
     *
     * @param {NSValues} prefix the key prefix to store the value in
     * @param {string} key the key to store the value in
     * @param {any} value the value to store
     * @param {string} orgName the partition to store the value in
     * @return {Promise<unknown>} a promise that resolves when the value is retrieved
     */
    public get(prefix: NSValues, key: any, orgName: string) {
        return get(constructKey(prefix, key, orgName), this.taguchiIdb);
    }

    /**
     * Remove all values in the cache store
     */
    public clear(): Promise<void> {
        return idbClear(this.taguchiIdb);
    }

    private async cleanup() {
        // Cleanup cache entries that haven't been used in the last N days.
        const now = Date.now();
        idbEntries(this.taguchiIdb).then((entries) => {
            if (!entries.length) {
                return;
            }
            entries.forEach((kv) => {
                const v = kv[1];
                if (v && now - v.timestamp > AppConfig.Defaults.cachedItemsDuration) {
                    idbDel(kv[0]);
                }
            });
        });
    }
}

const cacheSingleton = Cache.getInstance();
export default cacheSingleton;
