import Rx from 'rx';
import * as ExpressionParser from '../../ExpressionParser';
import * as Search from '../../modules/Search';
import { Store as UserStore, Actions as UserActions } from '../../modules/User';
import BaseStore from '../../BaseStore';
import * as CustomField from '../../modules/CustomField';
import { setCurrency } from '../../utils/Format';

import { searchResultMapper } from '../search/helpers';

import { List as ImmList } from 'immutable';
import { IUserOrganizationAccess, IUserOrganizationIssuer, UserOrganizationProfile } from '../../types/IUser';
import {
    GET_ORG_ISSUERS,
    SET_ORG_ISSUERS,
    GET_ORG_USER_ACCESS,
    SET_ORG_USER_ACCESS,
    FETCH_CUSTOMFIELDS,
    STORE_CUSTOMFIELDS,
    GET_ORGANIZATION_TOKENS,
    SET_ORGANIZATION_TOKENS,
    GET_USER_PROFILE,
    SET_USER_PROFILE,
    GET_USER_ORGANIZATION,
    SET_USER_ORGANIZATION,
    FETCH_PARTITIONSETS,
    STORE_PARTITIONSETS,
} from './mutation-types';

let orgUsersDisposable!: Rx.Disposable;
let orgIssuersDisposable!: Rx.Disposable;
let customFieldsDisposable!: Rx.Disposable;
let orgTokensDisposable!: Rx.Disposable;
let partitionSetsDisposable!: Rx.Disposable;

let userProfileDisposable: Rx.Disposable;
let userOrgDisposable: Rx.Disposable;

export default {
    FETCH_SEARCH_RESULTS: async ({ commit }, { organization, text, limit }) => {
        let source: Rx.Observable<any>;
        try {
            ExpressionParser.parse(text, {});
            source = Search.Store.searchTargetExpression(organization, text, limit);
        } catch (error) {
            source = await Search.Store.searchText(organization, text, limit);
        }

        return source
            .first()
            .flatMapLatest((data) => {
                const results = data
                    .map(searchResultMapper)
                    .sort((a, b) => b.type.localeCompare(a.type) || b.id - a.id)
                    .toArray()
                    .filter(
                        (value, index, arr) =>
                            arr.findIndex((valueToCompare) => valueToCompare.key === value.key) === index
                    );
                commit('SET_SEARCH_RESULTS', { results });
                return Rx.Observable.from([data]);
            })
            .catch((error) => {
                const err = Object.create(null);
                err.exception = error;
                err.expression = '';

                try {
                    ExpressionParser.parse(text, {});
                    err.expression = text;
                } catch (ignored) {
                    // ignore
                }

                return Rx.Observable.throw(err);
            })
            .toPromise();
    },

    CLEAR_SEARCH_RESULTS: ({ commit }) => commit('SET_SEARCH_RESULTS', { results: '' }),
    SET_SEARCH_VALUE: ({ commit }, { value }) => commit('SET_SEARCH_VALUE', { value }),

    [GET_ORG_USER_ACCESS]: ({ commit }, { organization }: { organization: UserOrganizationProfile }) => {
        if (orgUsersDisposable) {
            orgUsersDisposable.dispose();
        }

        return new Promise((resolve, reject) => {
            orgUsersDisposable = UserStore.getOrganizationUsers(organization).subscribe(
                (data: ImmList<IUserOrganizationAccess>) => {
                    const users = data.toArray();
                    commit(SET_ORG_USER_ACCESS, { users });
                    resolve(data);
                },
                reject
            );
        });
    },
    [GET_ORG_ISSUERS]: ({ commit }, { organization }: { organization: UserOrganizationProfile }) => {
        if (orgIssuersDisposable) {
            orgIssuersDisposable.dispose();
        }

        return new Promise((resolve, reject) => {
            UserStore.getOrganizationIssuers(organization).subscribe((data: ImmList<IUserOrganizationIssuer>) => {
                const issuers = data.toArray();
                commit(SET_ORG_ISSUERS, { issuers });
                resolve(data);
            }, reject);
        });
    },
    [FETCH_PARTITIONSETS]: ({ state, commit }, { organization }) => {
        if (partitionSetsDisposable) {
            partitionSetsDisposable.dispose();
        }
        return new Promise((resolve, reject) => {
            if (state.partitionSets && state.partitionSets.length) {
                resolve(state.partitionSets);
                return;
            }
            partitionSetsDisposable = BaseStore.connection(organization)
                .authObservable()
                .first()
                .subscribe(() => {
                    const partitionSets = ImmList(BaseStore.connection(organization).authInfo.partitionSets);
                    commit(STORE_PARTITIONSETS, { partitionSets });
                    resolve(partitionSets);
                }, reject);
        });
    },
    [FETCH_CUSTOMFIELDS]: ({ state, commit }, { organization }) => {
        if (customFieldsDisposable) {
            customFieldsDisposable.dispose();
        }
        return new Promise((resolve, reject) => {
            if (state.customFields && state.customFields.length) {
                resolve(state.customFields);
                return;
            }
            customFieldsDisposable = CustomField.Store.getItems(organization, 'any')
                .first()
                .subscribe((response) => {
                    if (!response) {
                        throw new Error('Unable to fetch subscriber custom fields during this time');
                    }
                    const customFields = response.sort((a, b) =>
                        a.field.toUpperCase().localeCompare(b.field.toUpperCase())
                    );
                    commit(STORE_CUSTOMFIELDS, { customFields });
                    resolve(customFields);
                }, reject);
        });
    },
    [GET_USER_PROFILE]: ({ commit }) => {
        if (userProfileDisposable) {
            userProfileDisposable.dispose();
        }
        return new Promise((resolve, reject) => {
            userProfileDisposable = UserActions.getUserProfile().subscribe((response) => {
                commit(SET_USER_PROFILE, { user: response.result });
                resolve(response.result);
            }, reject);
        });
    },
    [GET_USER_ORGANIZATION]: ({ commit }, { org }) => {
        if (userOrgDisposable) {
            userOrgDisposable.dispose();
        }
        return new Promise((resolve, reject) => {
            userOrgDisposable = UserStore.session()
                .getOrgProfile(org)
                .first()
                .subscribe((response) => {
                    const organization = response.first();
                    if (typeof organization === 'undefined') {
                        reject(new Error(`Unable to access the organization ${org}`));
                        return;
                    }

                    // Update global organization settings
                    const currency = organization.getIn(['settings', 'currency']);
                    if (currency) {
                        setCurrency(currency);
                    }

                    commit(SET_USER_ORGANIZATION, { organization });
                    resolve(organization);
                }, reject);
        });
    },
    [GET_ORGANIZATION_TOKENS]: ({ commit }) => {
        if (orgTokensDisposable) {
            orgTokensDisposable.dispose();
        }

        return new Promise((resolve, reject) => {
            orgTokensDisposable = UserStore.session()
                .getOrganizations()
                .filter((tokens) => tokens.count() > 0)
                .first()
                .subscribe((tokens) => {
                    commit(SET_ORGANIZATION_TOKENS, { tokens });
                    resolve(tokens);
                }, reject);
        });
    },
};
